<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/03/medical-syringe-on-calendar-background-UYWMRPG-scaled.jpg');"
      ></div>
      <div class="container">
        <div class="row">
          <div
            class="floating-box bg-default d-inline-block"
            style="width: 100%;"
          >
            <!-- <div class="box text-center">
              <div
                class="icon icon-shape bg-primary icon-xl rounded-circle text-white"
              >
                <i class="ni ni-building"></i>
              </div>
            </div> -->
            <blockquote class="text-center mt-4">
              <!-- <p class="mb-0 text-white">Refrigerator Procedure</p> -->
              <h1 class="display-4 text-white">
                Book with
                <span class="display-2 text-uppercase">{{
                  displayPharmacyName()
                }}</span>
              </h1>
              <!-- <footer class="blockquote-footer text-white">
                Someone famous in
                <cite title="Source Title">Source Title</cite>
              </footer> -->
            </blockquote>
            <h2
              class="lead text-left text-white p-4 text-left"
              style="max-width: 800px; margin:auto;"
            >
              Please select your vaccination appointment time below.<br />
              Please provide accurate information as appointments may be
              cancelled if insufficient information provided.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <iframe
      id="flexbookeriframe"
      :src="
        `https://a.flexbooker.com/widget/4ff2d6f7-5742-4e75-82db-ec2a16974aec?locationID=${id}`
      "
      style="width:100%;min-height:1000px"
    ></iframe>
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

export default {
  metaInfo() {
    return {
      titleTemplate: `%s - Book now at ${this.displayPharmacyName({
        format: "toLowerCase"
      })}`,
      meta: [
        {
          name: "description",
          content: `VaxRx.ca - COVID-19 vaccine avaliable now in Ontario, book your appointment with ${this.displayPharmacyName(
            { format: "toLowerCase" }
          )} in seconds`
        }
      ]
    };
  },
  bodyClass: "blog-post",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      id: "",
      name: ""
    };
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.name = this.$route.params.name;
  },
  methods: {
    displayPharmacyName(options) {
      return (
        this.name.replaceAll("-", " ")[options?.format || "toUpperCase"]() || ""
      );
    }
  }
};
</script>

<style>
li > ol > li {
  list-style: lower-roman;
}
</style>
