<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="
          background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/03/cheerful-pharmacist-holding-digital-tablet-and-smi-98L5GF6-scaled.jpg');
        "
      ></div>
      <div class="container">
        <div class="row">
          <div
            class="floating-box bg-default d-inline-block"
            style="width: 100%"
          >
            <blockquote class="text-center mt-4">
              <!-- <p class="mb-0 text-white">Refrigerator Procedure</p> -->
              <h1 class="display-4 text-white text-uppercase">
                Enroll your Pharmacy Today
              </h1>
              <!-- <footer class="blockquote-footer text-white">
                Someone famous in
                <cite title="Source Title">Source Title</cite>
              </footer> -->
            </blockquote>
            <h2
              class="lead text-left text-white p-4 text-left"
              style="max-width: 800px; margin: auto"
            >
              Register below to help patients find and schedule their
              vaccination appointments with you. Your account will be live and
              ready to access appointments in minutes.
            </h2>
            <h2 class="lead text-center text-white p-4 container-center">
              Do you already have a scheduling software and would like to be
              listed as an alternative? Click
              <a
                href="https://form.jotform.com/211227627480251"
                target="https://form.jotform.com/211227627480251"
                >here!</a
              >
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="container-center">
      <h2>Subsidized Pricing</h2>
      <div class="col-md-6" style="margin: auto">
        <card
          class="card-pricing card-background text-center"
          type="default"
          headerClasses="bg-transparent"
          footerClasses="bg-transparent"
          style="
            background-image: url('img/ill/pattern_pricing1.svg');
            background-size: contain;
            background-position-y: center;
            background-repeat: repeat;
          "
          data-background="image"
        >
          <template slot="body" class="col-md-12">
            <h6 class="card-category text-white text-uppercase">VaxRx</h6>
            <h1 class="card-title">
              <small class="text-white">$</small>34.99 / Month
            </h1>
            <ul>
              <li><b>200</b> appointments</li>
              <li>
                <small class="text-white">$</small><b>0.15</b> / appointment
                <br />after 200
              </li>
            </ul>
          </template>
        </card>
      </div>
    </div>
    <iframe
      id="JotFormIFrame-210696760967268"
      title="Pharmacy Enrollment Scheduler"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allowfullscreen="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/210696760967268"
      frameborder="0"
      style="min-width: 100%; height: 120vh; border: none"
      scrolling="yes"
    >
    </iframe>
    <div class="container-center">
      <p>
        VaxRx.ca was designed by pharmacists with the entire patient journey,
        and clinical workflow in mind.
      </p>
      <p>
        Patients can immediately find the nearest vaccination location/pharmacy,
        and schedule an appointment with a few clicks. They are then able to
        select if they would like text and/or email reminders for their
        appointment and are asked to complete a vaccination consent form, which
        is digitally provided to the pharmacy.
      </p>
      <p>
        Patients are able to modify or cancel their appointments with a few
        clicks from the confirmation email that they receive, and will
        automatically receive a record of immunization after their appointment.
      </p>
      <p>Patients are able to:</p>
      <ul>
        <li>Find nearest vaccination location</li>
        <li>View available time slots across multiple nearby sites</li>
        <li>Schedule and confirm appointments</li>
        <li>Modify or cancel appointments</li>
        <li>Digitally complete consent forms</li>
        <li>
          Receive email and/or text notifications, reminders, consent forms, and
          administration records
        </li>
      </ul>

      <p>
        Vaccination sites can manage their schedules through Google Calendar,
        which is the most intuitive and user friendly platform available.
      </p>
      <p>
        Once a patient has booked an appointment, it is automatically added to
        the calendar along with the completed consent form.
      </p>
      <p>
        Once the patient arrives for their appointment, the healthcare provider
        can simply open the appointment link and complete the administration
        record.
      </p>
      <p>
        We will also provide you with a custom booking link for your location
        that can be added to your own website to better support your patients!
      </p>

      <p>Vaccination sites are able to:</p>
      <ul>
        <li>
          Manage availability and appointment times through simple drag and drop
          interface
        </li>
        <li>Modify, or cancel appointments with a few clicks</li>
        <li>Access patient completed consent forms</li>
        <li>Complete administration record in seconds</li>
        <li>PHIPA and PIPEDA compliant</li>
        <li>Custom direct booking link provided</li>
        <li>Mass Data Upload to COVAXon</li>
      </ul>

      <!-- <h2>Subsidized Pricing</h2> -->

      <!-- <div class="row justify-content-md-center">
        <card class="text-center xs-12 md-3 offset-1 md-">
          <template slot="body">
              <h5 class="h2 card-title text-black mb-2">$34.99 / month</h5>
              <p class="card-description">includes 200 appointments per month and all amazing features</p>
              <p class="card-text text-sm font-weight-bold"><small class="text-muted">$0.15/appointment after 200 appointments</small></p>
          </template>
        </card>
      </div> -->

      <h2>VaxRx Manual and Product</h2>
      <a
        class="presentation"
        target="_blank"
        href="https://docs.google.com/presentation/d/e/2PACX-1vQEGkT8XRIPrvBAnUjYxiLoexJrwxraummK6JGaVrIEyo0qfWw_s7AZFt5Pk_7mIOTnaZ9yL29B7Xdv/pub?start=false&loop=false&delayms=3000"
      >
        <img
          :src="require('@/assets/img/vaxrx-presentation.png')"
          class="img-fluid"
        />
      </a>

      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

export default {
  bodyClass: "blog-post",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  }
};
</script>

<style lang="scss">
li > ol > li {
  list-style: lower-roman;
}
.card-pricing.card-background[data-background="image"] .card-body {
  padding-top: 40px !important;
}
.presentation {
  box-shadow: 0 19px 38px #00000030, 0 15px 12px #00000022;
  display: block;
}
.container-center {
  max-width: 800px;
  margin: 2rem auto;

  h2 {
    font-size: 1.8rem;
    margin: 4rem 1rem 2rem;
  }

  > p {
    font-size: 1rem;
    margin: 1rem;
  }
}
</style>
