<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/02/womans-hand-taking-pills-from-theeveryday-blister-8MVG28T-scaled.jpg');"
      ></div>
      <div class="container">
        <div class="row"></div>
        <div class="row">
          <div class="floating-box bg-default">
            <div class="box text-center">
              <div
                class="icon icon-shape bg-primary icon-xl rounded-circle text-white"
              >
                <i class="ni ni-bullet-list-67"></i>
              </div>
            </div>
            <blockquote class="blockquote text-center mt-4">
              <h1 class="display-3 text-white">
                Consent Form List
              </h1>
            </blockquote>
            <h2 class="lead text-white p-4">
              Please find below your list of Patient submitted Consent Forms
              This list is updated after each patient submission
            </h2>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="container-fluid">
        <!-- <div class="row"> -->
          <!-- <div> -->
            <!-- <div class="tools float-right">
              <base-dropdown menuClasses="dropdown-menu-right">
                <div
                  slot="title"
                  class="nav-link dropdown-toggle text-primary"
                  data-toggle="dropdown"
                >
                  <i class="ni ni-settings-gear-65"></i>
                </div>
                <a class="dropdown-item" href="javascript:;">Edit Profile</a>
                <a class="dropdown-item" href="javascript:;">Settings</a>
                <a class="dropdown-item" href="javascript:;">Log out</a>
                <a class="dropdown-item text-danger" href="javascript:;"
                  >Remove data</a
                >
              </base-dropdown>
            </div> -->
            <el-table
              class="table table-striped table-flush align-items-center mb-0"
              header-row-class-name="text-primary"
              :data="faxes"
            >
              <el-table-column
                min-width="50"
                label="Signed Date"
                header-align="left"
              >
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q102_date.month }} / {{ row.q102_date.day }} /
                  {{ row.q102_date.year }}
                </div>
              </el-table-column>
              <el-table-column
                min-width="50"
                label="First name"
                header-align="left"
                
              >
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q116_firstName }}
                </div>
              </el-table-column>
              <el-table-column
                min-width="50"
                label="Last name"
                header-align="left"
              >
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q117_lastName }}
                </div>
              </el-table-column>
              <el-table-column
                min-width="50"
                label="HealthCard Number"
                header-align="left"
              >
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q69_healthCard69 }}
                </div>
              </el-table-column>
              <el-table-column
                min-width="50"
                label="DOB (M/D/Y)"
                header-align="left"
              >
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q68_dateOf68.month }} / {{ row.q68_dateOf68.day }} /
                  {{ row.q68_dateOf68.year }}
                </div>
              </el-table-column>
              <el-table-column min-width="50" label="Dose" header-align="left">
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q134_isThis }}
                </div>
              </el-table-column>
              <el-table-column
                min-width="50"
                label="Name of First Vaccine"
                header-align="left"
              >
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q173_nameOf }}
                </div>
              </el-table-column>
              <el-table-column
                min-width="50"
                label="Date of first Vaccine"
                header-align="left"
              >
                <div slot-scope="{ row }" class="td-number">
                  {{ row.q8_ifSecond.month }} / {{ row.q8_ifSecond.day }} /
                  {{ row.q8_ifSecond.year }}
                </div>
              </el-table-column>
            </el-table>
          </div>
        <!-- </div> -->
      <!-- </div> -->
    </section>
  </div>
</template>

<script>
import { Glide, GlideSlide } from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from "element-ui";
// import BaseDropdown from "@/components/BaseDropdown";
import FaxDataService from "../services/FaxDataService";

export default {
  data() {
    return {
      search: "",
      expanded: [],
      singleExpand: false,
      headers: [
        // {
        //   text: "_id",
        //   align: "start",
        //   filterable: true,
        //   value: "_id"
        // },
        // { text: "OCR", filterable: true, value: "data-table-expand" },
        {
          type: ""
        }
        // { text: "URL", value: "URL" },
        // { text: "To", value: "To" },
        // { text: "TimeStamp", value: "TimeStamp" },
        // { text: "SID", value: "SID" }
      ],
      faxes: [],
      currentPage: 1
    };
  },
  bodyClass: "blog-post",
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
    // BaseDropdown
  },
  methods: {
    retrieveFaxes() {
      FaxDataService.getAll()
        .then(response => {
          this.faxes = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    customDataTableItemsFilter(value, search, items) {
      /*
                  Filter for individual words in search string. Filters
                  all object values rather than just the keys included
                  in the data table headers.
                   */
      const wordArray = search
        .toString()
        .toLowerCase()
        .split(" ")
        .filter(x => x);
      return wordArray.every(word =>
        JSON.stringify(Object.values(items))
          .toString()
          .toLowerCase()
          .includes(word)
      );
    }
  },
  mounted() {
    this.retrieveFaxes();
  }
};
</script>

<style>
li > ol > li {
  list-style: lower-roman;
}
</style>
