import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import http from "../src/api/http";
import Meta from "vue-meta";
import { Auth0Plugin } from "../src/auth";
import { domain, clientId, audience } from "../auth_config.json";
import { getInstance } from "./auth/authWrapper.js";
import VueCookies from "vue-cookies";

Vue.use(Meta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.$http = Vue.prototype.$http = http;

Vue.use(VueCookies);
Vue.$http.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${Vue.$cookies.get("token")}`;
  return config;
});
Vue.$http.interceptors.response.use(
  resp => resp,
  async error => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      Vue.$cookies.set("token", await getInstance().getTokenSilently());
      return Vue.$http.request(error.config); // re-send the request with no error
    }
    return error; // any other error than status 401
  }
);
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = true;
Vue.use(Argon);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
