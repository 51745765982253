<template>
  <div class="wrapper">
    <div class="page-header header-filter">
      <div
        class="page-header-image"
        style="background-image: url('https://pharmaguide.ca/wp-content/uploads/2021/03/cheerful-pharmacist-holding-digital-tablet-and-smi-98L5GF6-scaled.jpg');"
      ></div>
      <div class="container">
        <div class="row">
          <div
            class="floating-box bg-default d-inline-block"
            style="width: 100%;"
          >
            <blockquote class="text-center mt-4">
              <!-- <p class="mb-0 text-white">Refrigerator Procedure</p> -->
              <h1 class="display-4 text-white text-uppercase">
                Our sponsors
              </h1>
              <!-- <footer class="blockquote-footer text-white">
                Someone famous in
                <cite title="Source Title">Source Title</cite>
              </footer> -->
            </blockquote>
            <h2 class="lead text-center text-white p-4 container-center">
              Special Thank you to our sponsors and partners who have helped
              make VaxRx.ca possible.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container-center">
      <h2>Help us help Canadians get vaccinated!</h2>

      <p>
        Help us help Canadians get vaccinated! We are all in this together but
        need your support to make VaxRx.ca even better.
        <u>This is a non-profit initiative</u> - all funds collected are used to
        cover the costs for vaccination sites to be listed on VaxRx.ca.
      </p>
      <p>
        Corporate Sponsors will have their logo added to our sponsors area and
        will support our vaccinators in scheduling appointments.
      </p>

      <div class="card-deck">
        <card class="card-blog">
          <template slot="image">
            <img
              class="card-img-top"
              src="https://pharmaguide.ca/wp-content/uploads/2020/10/PHOX_Icon_FullColor.png"
              alt="Image placeholder"
            />
          </template>
          <template slot="body">
            <h5 class="card-title">PharmaGuide Inc.</h5>
            <p class="card-text">
              specializes in equipping healthcare providers with solutions to
              increase efficiency and improve patient outcomes.
            </p>
            <p class="card-text">
              <small class="text-muted"
                ><a href="https://www.pharmaguide.ca" target="_blank"
                  >pharmaguide.ca</a
                ></small
              >
            </p>
          </template>
        </card>
        <card class="card-blog">
          <template slot="image">
            <img
              class="card-img-top"
              src="https://static.tildacdn.com/tild3832-6361-4433-b430-366561326563/PHARMASSIST-FULL-CMY.png"
              alt="Image placeholder"
            />
          </template>
          <template slot="body">
            <h5 class="card-title">Pharmassist Rx</h5>
            <p class="card-text">
              We value our patients and their health. We provide the best
              pharmacy services in the most convenient way at the most
              competitive prices.
            </p>
            <p class="card-text">
              <small class="text-muted"
                ><a href="https://pharmassistrx.ca/" target="_blank"
                  >pharmassistrx.ca</a
                ></small
              >
            </p>
          </template>
        </card>
        <card class="card-blog">
          <template slot="image">
            <img
              class="card-img-top"
              src="https://mypharmasmart.com/wp-content/uploads/2019/11/PharmaSmart-e1572660319837.png"
              alt="Image placeholder"
            />
          </template>
          <template slot="body">
            <h5 class="card-title">PharmaSmart</h5>
            <p class="card-text">
              pharmaceutical care through compassion, science and direct
              collaboration with Doctors and specialists.
            </p>
            <p class="card-text">
              <small class="text-muted"
                ><a href="https://mypharmasmart.com" target="_blank"
                  >mypharmasmart.com</a
                ></small
              >
            </p>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
// import Team from "./sections/Teams/Team1.vue";

export default {
  components: {
    // Team
  },
  data() {
    return {
      isActive: false
    };
  }
};
</script>
<style lang="scss">
.content {
  margin-top: 4rem;
}
.card-deck {
  margin-top: 4rem;

  .card-image {
    display: flex;
    width: 100%;
    height: 160px;
  }
  .card-img-top {
    object-fit: contain;
    width: 100%;
    padding: 1rem;
    height: 160px;
    display: block;
    align-items: center;
    justify-content: center;
  }
}
.container-center {
  max-width: 800px;
  margin: 2rem auto;

  h2 {
    font-size: 1.8rem;
    margin: 2rem 1rem;
  }

  > p {
    font-size: 1.2rem;
    margin: 2rem 1rem;
  }
}
</style>
