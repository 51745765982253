import { getInstance } from "./authWrapper";
import VueCookies from "vue-cookies";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    if (authService.isAuthenticated) {
      VueCookies.set("token", await getInstance().getTokenSilently());
      return next();
    }

    return authService.loginWithRedirect({
      // appState: { targetUrl: "/phox/profile" }
      appState: { targetUrl: "compliance" }
    });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
