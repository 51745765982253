var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('section',{staticClass:"section"},[_c('div',{staticClass:"container-fluid"},[_c('el-table',{staticClass:"table table-striped table-flush align-items-center mb-0",attrs:{"header-row-class-name":"text-primary","data":_vm.faxes}},[_c('el-table-column',{attrs:{"min-width":"50","label":"Signed Date","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q102_date.month)+" / "+_vm._s(row.q102_date.day)+" / "+_vm._s(row.q102_date.year)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"50","label":"First name","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q116_firstName)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"50","label":"Last name","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q117_lastName)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"50","label":"HealthCard Number","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q69_healthCard69)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"50","label":"DOB (M/D/Y)","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q68_dateOf68.month)+" / "+_vm._s(row.q68_dateOf68.day)+" / "+_vm._s(row.q68_dateOf68.year)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"50","label":"Dose","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q134_isThis)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"50","label":"Name of First Vaccine","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q173_nameOf)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"50","label":"Date of first Vaccine","header-align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-number"},[_vm._v(" "+_vm._s(row.q8_ifSecond.month)+" / "+_vm._s(row.q8_ifSecond.day)+" / "+_vm._s(row.q8_ifSecond.year)+" ")])}}])})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header header-filter"},[_c('div',{staticClass:"page-header-image",staticStyle:{"background-image":"url('https://pharmaguide.ca/wp-content/uploads/2021/02/womans-hand-taking-pills-from-theeveryday-blister-8MVG28T-scaled.jpg')"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"floating-box bg-default"},[_c('div',{staticClass:"box text-center"},[_c('div',{staticClass:"icon icon-shape bg-primary icon-xl rounded-circle text-white"},[_c('i',{staticClass:"ni ni-bullet-list-67"})])]),_c('blockquote',{staticClass:"blockquote text-center mt-4"},[_c('h1',{staticClass:"display-3 text-white"},[_vm._v(" Consent Form List ")])]),_c('h2',{staticClass:"lead text-white p-4"},[_vm._v(" Please find below your list of Patient submitted Consent Forms This list is updated after each patient submission ")])])])])])}]

export { render, staticRenderFns }